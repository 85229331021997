import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import {
  getTranslate,
  getConvertTextsoomaali,
} from "../../../../../store/actions/appActions"; // Adjust path as needed
import Loader from "../../../../../component/loader";
import debounce from "lodash/debounce";
import { FixedSizeList } from "react-window";
const ShomaliaLanguage = ({ language }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedSuggestions, setCheckedSuggestions] = useState([]);
  const [translations, setTranslations] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const searchBoxRef = useRef(null);
  const audioPlayerRef = useRef(new Audio());
  const [loading, setLoading] = useState(false);
  const [voices, setVoices] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  useEffect(() => {
    setFilteredSuggestions(language);
  }, [language]);

  const debounceSearch = useCallback(
    debounce((value) => {
      if (value.trim() !== "") {
        const filtered = language.filter((item) =>
          item?.soomaali?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
      } else {
        setFilteredSuggestions(language);
      }
    }, 300),
    [language]
  );

  // const handleSearchChange = (event) => {
  //   const value = event.target.value ?? "";
  //   setSearchTerm(value);
  //   debounceSearch(value);
  // };

  useEffect(() => {
    setFilteredSuggestions(language);
  }, [language]);

  const handleSearchChange = (event) => {
    const value = event.target.value ?? "";
    setSearchTerm(value);
    console.log(value, "searchvalue");
    if (value?.trim() !== "") {
      const filteredSuggestions = language.filter((item) =>
        item?.soomaali?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredSuggestions(filteredSuggestions);
    } else {
      setFilteredSuggestions(language);
    }
  };

  const handleCheckboxChange = (event, suggestion) => {
    const isChecked = event.target.checked;
    setCheckedSuggestions((prevChecked) =>
      isChecked
        ? [...prevChecked, suggestion]
        : prevChecked.filter((item) => item !== suggestion)
    );
  };

  const searchTranslations = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        getTranslate(checkedSuggestions, "soomaali")
      );
      if (response.success) {
        setTranslations(Object.values(response.data.translations));
      } else {
        console.error("Translation API error:", response.message);
      }
    } catch (error) {
      console.error("Failed to get translation:", error);
    } finally {
      setLoading(false);
    }
  };

  const speakText = (text) => {
    const message = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(message);
  };

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
    };

    // Load voices and set up an event listener to update the voices list
    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  const speakTextArabic = (text) => {
    if (!("speechSynthesis" in window)) {
      alert("Sorry, your browser does not support text to speech!");
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    console.log(utterance, "vocing");
    const arabicVoice = voices.find((voice) => voice.lang === "ar-SA");

    if (!arabicVoice) {
      alert("Sorry, Arabic voice is not available in your browser.");
      return;
    }

    utterance.voice = arabicVoice;
    window.speechSynthesis.speak(utterance);
  };

  useEffect(() => {
    const audioPlayer = audioPlayerRef.current;
    const handleAudioError = (e) => {
      console.error("Error loading audio file:", e);
    };

    audioPlayer.addEventListener("error", handleAudioError);
    return () => {
      audioPlayer.removeEventListener("error", handleAudioError);
    };
  }, []);

  const Row = ({ index, style }) => (
    <ListItem style={style} key={index}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedSuggestions.includes(
              filteredSuggestions[index].soomaali
            )}
            onChange={(event) =>
              handleCheckboxChange(event, filteredSuggestions[index].soomaali)
            }
          />
        }
        label={filteredSuggestions[index].soomaali}
      />
    </ListItem>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center", position: "relative" }}
        ref={searchBoxRef}
      >
        <TextField
          placeholder="Search"
          size="small"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{
            "& .MuiInputBase-root": {
              padding: 0,
              borderRadius: "25px",
              "&:hover": {
                borderColor: "#f7f7f7",
              },
              "&.Mui-focused": {
                boxShadow: "none",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              color: "#f7f7f7",
            },
            borderRadius: "25px",
            backgroundColor: "white",
          }}
          InputProps={{
            sx: { padding: 0 },
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    onClick={searchTranslations}
                    sx={{
                      backgroundColor: "transparent",
                      color: "grey",
                      padding: "0.5rem",
                      borderRadius: "0px 5px 5px 0px",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "grey",
                      },
                    }}
                  >
                    <SearchOutlinedIcon />
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
        {filteredSuggestions.length > 0 && (
          <FixedSizeList
            height={200}
            width="100%"
            itemSize={46}
            itemCount={filteredSuggestions.length}
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              zIndex: 10,
              marginTop: "0.5rem",
            }}
          >
            {Row}
          </FixedSizeList>
        )}
      </Box>
      <FormGroup
        sx={{
          width: "40%",
          mt: 2,
          height: "30vh",
          overflowY: "auto",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {checkedSuggestions.map((suggestion, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={checkedSuggestions.includes(suggestion)}
                onChange={(event) => handleCheckboxChange(event, suggestion)}
              />
            }
            label={suggestion}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              "& .MuiFormControlLabel-label": {
                marginRight: "auto",
              },
            }}
            labelPlacement="start"
          />
        ))}
      </FormGroup>

      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          marginTop: "2rem",
          position: "absolute",
          bottom: 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#b4acff",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "300px",
            height: "30vh",
            overflow: "auto",
          }}
        >
          <Typography
            sx={{ fontSize: "1.5rem", fontWeight: "700" }}
            textAlign={"center"}
          >
            English
          </Typography>
          <br />
          {translations.length > 0 && (
            <Box>
              {translations.map((text, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    maxWidth: "500px",
                    minWidth: "200px",
                    marginBottom: "1rem",
                  }}
                  gap={5}
                >
                  <Typography>{text.english}</Typography>
                  <IconButton onClick={() => speakText(text.english)}>
                    <VolumeUpIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: "#e0c7ff",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "30vh",
            overflow: "auto",
            minWidth: "300px",
          }}
        >
          <Typography
            textAlign={"center"}
            sx={{ fontWeight: "700", fontSize: "1.5rem" }}
          >
            Arabic
          </Typography>
          <br />
          {loadingTranslations ? (
            <Loader />
          ) : (
            translations.length > 0 && (
              <Box>
                {translations.map((text, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      maxWidth: "500px",
                      minWidth: "200px",
                      marginBottom: "1rem",
                    }}
                    gap={5}
                  >
                    <Typography>{text.arabic}</Typography>
                    <IconButton
                      onClick={() => speakTextArabic(text.arabic)}
                      disabled={loadingTranslations}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ShomaliaLanguage;
